const STATES = [
  { label: "Perlis", value: "Perlis" },
  { label: "Kedah", value: "Kedah" },
  { label: "Pulau Pinang", value: "Pulau Pinang" },
  { label: "Perak", value: "Perak" },
  { label: "Pahang", value: "Pahang" },
  { label: "Selangor", value: "Selangor" },
  { label: "Kuala Lumpur", value: "Kuala Lumpur" },
  { label: "Negeri Sembilan", value: "Negeri Sembilan" },
  { label: "Melaka", value: "Melaka" },
  { label: "Johor", value: "Johor" },
  { label: "Terengganu", value: "Terengganu" },
  { label: "Kelantan", value: "Kelantan" },
  { label: "Sabah", value: "Sabah" },
  { label: "Sarawak", value: "Sarawak" },
  { label: "Singapore", value: "Singapore" },
];

const SHIPMENT_TYPE = [
  { label: "Outlet", value: "Outlet" },
  // { label: 'B2BPRO', value: 'B2BPRO' },
  // { label: 'B2C (Distributor Order)', value: 'B2C (Distributor Order)' },
  { label: "Singapore", value: "Singapore" },
];

const USER_CATEGORY = [
  { label: "Gold", value: "Gold" },
  { label: "Silver", value: "Silver" },
  { label: "Platinum", value: "Platinum" },
];

const ORDER_STATUS = [
  { label: "Active" },
  { label: "Assigned" },
  { label: "Generated" },
  { label: "In Warehouse" },
  { label: "In Transit" },
  { label: "Arrived At Branch" },
  { label: "Assigned For Last Mile" },
  { label: "Out For Delivery" },
  { label: "Delivered" },
  { label: "Close Store" },
  { label: "Cancelled" },
];

export { STATES, SHIPMENT_TYPE, USER_CATEGORY, ORDER_STATUS };
